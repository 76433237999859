// TODO: We might want to import individual polyfills here to reduce the bundle size
import 'core-js';

import { StrictMode } from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { I18nextProvider } from "react-i18next";
import favicon from "./assets/images/favicon.ico";
import App from "./App";
import "./i18n";

import "./index.css";

const link = document.createElement("link");
link.rel = "icon";
link.href = favicon;
document.head.appendChild(link);

const container = document.getElementById("root");

/* eslint-disable @typescript-eslint/no-non-null-assertion */
const root = createRoot(container!);

root.render(
  <>
    <BrowserRouter>
      <Routes>
        <Route path="/*" element={<App />} />
      </Routes>
    </BrowserRouter>
  </>,
);
