import { SurveyOutcomeEvent } from "interfaces/SurveyOutcomeEvent";

export function notifyCloseWebView() {
	console.log("Notify Close Web View");
	try {
		if (window.webkit) {
			window.webkit.messageHandlers.nativeSurveyClosed.postMessage({
				message: "msg",
			});
		}

		if (window.androidInterface) {
			window.androidInterface.nativeSurveyClosed();
		}
	} catch (error) {
		console.log(error);
	}
}

export function notifyNativeSurveyOutcome(event: SurveyOutcomeEvent) {
	var eventData = JSON.stringify(event);
	console.log("Notify Native Survey Outcome: ", event);

	try {
		if (window.webkit) {
			window.webkit.messageHandlers.surveyOutcome.postMessage(eventData);
		}

		if (window.androidInterface) {
			window.androidInterface.surveyOutcome(eventData);
		}
	} catch (error) {
		console.log(error);
	}
}
