import axiosTokenInterceptor from "interceptors/tokenInterceptor";

export const getPanelistConfiguration = async (
    hashedAppUserId: string,
    originalAppUserId: string,
    deviceId: string,
    sessionId: string,
    languagePreference?: string | undefined
  ) => {
    const params = new URLSearchParams();
  
    if (languagePreference) {
      params.append("languageString", encodeURIComponent(languagePreference));
    }

    params.append("originalAppUserId", encodeURIComponent(originalAppUserId));
    params.append("deviceId", deviceId);
    params.append("sessionId", sessionId);
    
  
    const request = `${process.env.API_URL}/panelists/${hashedAppUserId}/configuration`;
    const response = await axiosTokenInterceptor.get(request, { params });
  
    return response.data;
  };