import { ReactNode, useEffect, useState } from "react";
import { IoCloseCircle } from "react-icons/io5";
import * as c from "./Modal.module.css";

interface OfferModalProps {
  show: boolean;
  onClose: () => void;
  children: ReactNode;
}

const OfferModal = ({ show, onClose, children }: OfferModalProps) => {
  var os = detectMobileOS();

  function detectMobileOS() {
    var userAgent = navigator.userAgent || navigator.vendor || window.opera;

    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      return "iOS";
    }

    if (/android/i.test(userAgent)) {
      return "Android";
    }

    return "";
  }

  if (!show) return null;

  return (
    <>
      {/* <div className={c.backdrop} onClick={onClose}></div> */}
      <div className={`${c["modal-overlay"]} ${os === "iOS" && c["modal-overlay-ios"]}`}>
        <div className={c.modal}>
          <button
            className={`${c["close-button"]} ${os === "iOS" && c["close-button-ios"]}`}
            onClick={onClose}
          >
            <IoCloseCircle />
          </button>
          <div className={c.content}>{children}</div>
        </div>
      </div>
    </>
  );
};

export default OfferModal;
