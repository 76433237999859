import { useEffect, useState } from "react";
import { FaCheck, FaClock } from "react-icons/fa";
import { BiSupport } from "react-icons/bi";
import { BsInfoCircle } from "react-icons/bs";
import { Goal } from "interfaces/Goal";
import { transformCurrency } from "utils/functions/currencyModUtil";
import * as c from "./RewardModalGoalTask.module.css";

interface RewardModalGoalTaskProps {
  goal: Goal;
  currencyName: string;
  index: number;
  daysRemaining: number;
  hoursRemaining: number;
  indexZeroTitle: string;
}

const RewardModalGoalTask = ({
  goal,
  currencyName,
  index,
  daysRemaining,
  hoursRemaining,
  indexZeroTitle,
}: RewardModalGoalTaskProps) => {
  const { id, title, description, rewardIac, isCompleted, isPending } = goal;
  const currency = transformCurrency(currencyName, rewardIac);
  const [completedTooltipVisible, setCompletedTooltipVisible] = useState(false);
  const [titleTooltipVisible, setTitleTooltipVisible] = useState(false);
  const [infoTooltipVisible, setInfoTooltipVisible] = useState(false);

  useEffect(() => {
    let timer: NodeJS.Timeout;
    if (completedTooltipVisible) {
      timer = setTimeout(() => {
        setCompletedTooltipVisible(false);
      }, 1000);
    }
    return () => clearTimeout(timer);
  }, [completedTooltipVisible]);

  useEffect(() => {
    let timer: NodeJS.Timeout;
    if (titleTooltipVisible) {
      timer = setTimeout(() => {
        setTitleTooltipVisible(false);
      }, 2000);
    }
    return () => clearTimeout(timer);
  }, [titleTooltipVisible]);

  useEffect(() => {
    let timer: NodeJS.Timeout;
    if (infoTooltipVisible) {
      timer = setTimeout(() => {
        setInfoTooltipVisible(false);
      }, 6000);
    }
    return () => clearTimeout(timer);
  }, [infoTooltipVisible]);

  const toggleCompletedTooltip = () => {
    setCompletedTooltipVisible(!completedTooltipVisible);
  };

  const toggleTitleTooltip = () => {
    setTitleTooltipVisible(!titleTooltipVisible);
  };

  const toggleInfoTooltip = () => {
    setInfoTooltipVisible(!infoTooltipVisible);
  };

  const renderStatus = () => {
    if (isCompleted) {
      return null;
    }

    if (isPending) {
      if (index === 0) {
        if (daysRemaining !== null && daysRemaining > 0) {
          return (
            <span className={c["support-status-text"]}>
              Please allow up to {daysRemaining} day{daysRemaining > 1 ? "s" : ""} to credit
            </span>
          );
        } else if (hoursRemaining !== null && hoursRemaining > 0) {
          return (
            <span className={c["support-status-text"]}>
              Please allow up to {hoursRemaining} hour{hoursRemaining > 1 ? "s" : ""} to credit
            </span>
          );
        }
        /* return (
          <span className={c["support-status-icon"]}>
            <BiSupport color={"#00a5ed"} />
            <span className={c["support-status-icon-text"]}>Contact Support</span>
          </span>
        ); */
      }

      if (index === 1) {
        if (/^install$/i.test(indexZeroTitle)) {
          if (daysRemaining !== null && daysRemaining > 0) {
            return (
              <span className={c["support-status-text"]}>
                Please allow up to {daysRemaining} day{daysRemaining > 1 ? "s" : ""} to credit
              </span>
            );
          } else if (hoursRemaining !== null && hoursRemaining > 0) {
            return (
              <span className={c["support-status-text"]}>
                Please allow up to {hoursRemaining} hour{hoursRemaining > 1 ? "s" : ""} to credit
              </span>
            );
          }
          /* return (
            <span className={c["support-status-icon"]}>
              <BiSupport color={"#00a5ed"} />
              <span className={c["support-status-icon-text"]}>Contact Support</span>
            </span>
          ); */
        }
        /* return (
          <span className={c["support-status-icon"]}>
            <BiSupport color={"#00a5ed"} />
            <span className={c["support-status-icon-text"]}>Contact Support</span>
          </span>
        ); */
      }

      if (index > 1) {
        /* return (
          <span className={c["support-status-icon"]}>
            <BiSupport color={"#00a5ed"} />
            <span className={c["support-status-icon-text"]}>Contact Support</span>
          </span>
        ); */
      }
    }

    return null;
  };

  const currencyRender = () => {
    if (!isCompleted && !isPending) {
      return (
        <div className={c["tooltip-container"]}>
          {infoTooltipVisible && (
            <div className={c.tooltip}>
              <div className={c["tooltip-text"]}>
                <strong>When you finish this goal, you'll earn this reward!</strong> <br />
                <br /> <i>Note:</i> You might have already started or finished this goal. We'll get
                a confirmation from our partner when your progress moves from "In-Progress" to
                "Completed." You can work on this goal now, and you'll get credit when it's done!
                Have fun!
              </div>
            </div>
          )}
          <span className={c["task-currency-inline"]}>
            {currency} <BsInfoCircle onClick={toggleInfoTooltip} />
          </span>
        </div>
      );
    }

    if (isCompleted && !isPending) {
      return (
        <span className={c["task-currency"]}>
          {currency} / {currency}
        </span>
      );
    }

    if (!isCompleted && isPending) {
      return <span className={c["task-currency"]}>0 / {currency}</span>;
    }
  };

  return (
    <div className={c.task}>
      <span
        className={`${c["task-number"]} ${isCompleted && c["completed-task-number"]} ${
          isPending && c["pending-task-number"]
        }`}
      >
        {index + 1}
      </span>
      <div
        className={`${c["task-content-container"]} ${
          isCompleted && c["completed-task-container"]
        } ${isPending && c["pending-task-container"]}`}
      >
        <span onClick={toggleTitleTooltip}>{title}</span>
        {titleTooltipVisible && <div className={c["tooltip-title"]}>{title}</div>}
        <div className={c["task-content-wrapper"]}>
          {isCompleted && (
            <>
              <span className={c["task-status-complete"]} onClick={toggleCompletedTooltip}>
                <FaCheck />
                <span>Complete</span>
              </span>
              {completedTooltipVisible && <div className={c["tooltip-completed"]}>Complete</div>}
            </>
          )}
          {isPending && (
            <>
              <span className={c["task-status-pending"]}>
                <FaClock />
                <span>In-Progress</span>
              </span>
            </>
          )}
          {currencyRender()}

          {renderStatus()}
        </div>
      </div>
    </div>
  );
};

export default RewardModalGoalTask;
