import * as c from "./OfferModalDescriptionTask.module.css";

interface OfferModalDescriptionTaskProps {
  description: string;
}

const OfferModalDescriptionTask = ({ description }: OfferModalDescriptionTaskProps) => {
  return (
    <div className={c.task}>
      <div className={c["task-content-container"]}>
        <span>{description}</span>
      </div>
    </div>
  );
};

export default OfferModalDescriptionTask;
