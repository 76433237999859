import { TiDeviceDesktop, TiVendorAndroid } from "react-icons/ti";
import { TiVendorApple } from "react-icons/ti";
import { SiMacos } from "react-icons/si";
import { ImWindows } from "react-icons/im";
import { FaAmazon } from "react-icons/fa";
import { Offer } from "interfaces/Offer";
import { transformCurrency } from "utils/functions/currencyModUtil";
import * as c from "./OfferCardItem.module.css";

interface OfferCardItemProps {
  onModalOpen: (offer: Offer) => void;
  offer: Offer;
  currencyName: string;
}

type Platform = "Android" | "iOS" | "Windows";

const platformIcons: Record<Platform, JSX.Element> = {
  Android: <TiVendorAndroid color="#A6D864" />,
  iOS: <TiVendorApple />,
  Windows: <TiDeviceDesktop size={14} color="#00a5ed" />
};

function OfferCardItem({ onModalOpen, offer, currencyName }: OfferCardItemProps) {
  const currency = transformCurrency(currencyName, offer.rewardIac);
  const isMultiGoal = offer.goals.length > 1;

  return (
    <div className={c.card} onClick={() => onModalOpen(offer)}>
      <div className={c["card-content"]}>
        <img src={offer.thumbnailUrl} alt="Logo" className={c.logo} />
        <div className={c["text-content"]}>
          <p className={c["title"]}>{offer.title}</p>
          {offer.operatingSystems !== null && offer.operatingSystems.length !== 0 && (
            <div className={c.icons}>
              {offer.operatingSystems.map((platform) => (
                <span key={platform}>{platformIcons[platform as Platform] || null}</span>
              ))}
            </div>
          )}
        </div>
        <div className={c.points}>
          <span>{isMultiGoal && "Earn up to"}{isMultiGoal && <br/>} <b>{currency}</b></span>
        </div>
      </div>
    </div>
  );
}

export default OfferCardItem;
