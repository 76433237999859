import { useEffect, useRef, useState } from "react";
import { AxiosError } from "axios";
import { createSearchParams, useNavigate, useParams, useSearchParams } from "react-router-dom";
import { useErrorHandler } from "contexts/errorContext";
import { useColorContext } from "contexts/colorContext";
import { useTranslation } from "react-i18next";
import { sha256 } from "js-sha256";
import { v4 as uuidv4 } from "uuid";
import { jwtDecode } from "jwt-decode";
import { loadTranslationResource } from "../../i18n";
import { Configuration } from "interfaces/Configuration";
import { JwtToken } from "interfaces/Token";
import { Geolocation } from "interfaces/Geolocation";
import { PublisherConfig } from "interfaces/PublisherConfig";
import { PanelistConfig } from "interfaces/PanelistConfig";
import { requestToken } from "api/auth";
import { getGeolocation } from "api/profiler";
import { getPublisherConfig } from "api/publisher";
import { getNativeSurveyLink } from "api/nativeSurveys";
import {
  savePublisherFlags,
  saveColors,
  saveCurrencySales,
  setCurrentSurvey,
  setCacheConfiguration,
  clearAllFromLocalStorage,
  getCachedConfiguration,
} from "utils/functions/configurationUtil";
import { ProductTypes, DropGender } from "utils/constants/enums";
import { DEFAULT_LANG } from "utils/constants/keys";
import Spinner from "components/ui/Spinner";

import * as c from "./ConfigurationPage.module.css";
import { getPanelistConfiguration } from "api/panelist";
import { PanelistStages } from "interfaces/PanelistStages";
import { DeviceTypes } from "interfaces/DeviceTypes";

let configuration: Configuration = {};

function ConfigurationPage() {
  const { t } = useTranslation();
  const params = useParams();
  const [searchParams] = useSearchParams();
  const handleError = useErrorHandler();
  const navigate = useNavigate();
  const isMountedRef = useRef(true);
  const hasNavigatedRef = useRef(false);
  const existingConfig = getCachedConfiguration();
  const { setPrimaryColorCtx, setSecondaryColorCtx } = useColorContext();

  const configParams = searchParams.get("params");
  const publisherUserId = searchParams.get("app_uid");

  const firstRender = useRef(true);

  useEffect(() => {
    if (!firstRender.current) {
      return;
    }

    firstRender.current = false;

    const timer = setTimeout(() => {
      if (isMountedRef.current && !hasNavigatedRef.current) {
        navigate('/config');
      }
    }, 10000);//iOS hack to ensure isLoaded flag works


    //this can be used if you want to reload the /configuration route from something like the error page
    const loadExistingConfig = Boolean(searchParams.get("load_existing_config"));
    if (loadExistingConfig && existingConfig) {
      configureApp(existingConfig);
      return;
    }

    if (!configParams || !publisherUserId) {
      return;
    }

    const newConfiguration: Configuration = {
      client_id: searchParams.get("client_id") || "",
      client_secret: searchParams.get("client_secret") || "",
      device_id: searchParams.get("device_id") || "",
      hashed_publisher_app_uid: sha256.hex(publisherUserId as string),
      publisher_app_uid: publisherUserId || "",
      currency_name: searchParams.get("currency_name") || "",
      currency_rate: Number(searchParams.get("currency_rate")),
      session_uid: searchParams.get("session_uid") || "",
      language: searchParams.get("language") || "",
      survey_id: searchParams.get("survey_id") || "",
      placement_id: searchParams.get("placement_id") || "",
      search_id: searchParams.get("search_id") || "",
      params: configParams || "",
      referrer: window.location.href,
    };

    if (
      (newConfiguration.client_id &&
        newConfiguration.client_secret &&
        newConfiguration.publisher_app_uid) ||
      (newConfiguration.params && newConfiguration.publisher_app_uid)
    ) {
      configureAppWithListener(newConfiguration);
    }

    return () => {
      window.removeEventListener("setConfiguration", handleSetConfiguration);
      clearTimeout(timer);
      isMountedRef.current = false;
    };
  }, [navigate]);

  const configureAppWithListener = (newConfig: Configuration) => {
    configuration = newConfig;
    configuration.referrer = window.location.href;
    configuration.publisher_app_uid = configuration.publisher_app_uid || configuration.app_uid;
    configuration.hashed_publisher_app_uid = sha256.hex(configuration.publisher_app_uid as string);
    configuration.device_id = configuration.device_id || uuidv4();
    configuration.product_type = configuration.survey_id ? ProductTypes.NativeSurveys : ProductTypes.SurveysWall;
    hasNavigatedRef.current = true;
    clearAllFromLocalStorage();
    setCacheConfiguration(configuration);
    configureApp(configuration);
  };

  const handleSetConfiguration: EventListener = (event) => {
    configureAppWithListener((event as CustomEvent).detail);
  };

  //window.addEventListener("setConfiguration", handleSetConfiguration);

  (window as any).setConfiguration = configureAppWithListener;

  //Main Entry point regardless of configuration approach
  const configureApp = (configuration: Configuration) => {
    requestToken(configuration)
      .then((token: JwtToken) => {
        if (configuration.params) {
          const tokenInfo: any = jwtDecode(token.access_token);
          configuration.client_id = tokenInfo.client_id;
        }
        geoLocateUser(configuration);
      })
      .catch((error) => {
        handleError(error);
      });
  };

  const geoLocateUser = (configuration: Configuration) => {
    getGeolocation(configuration.hashed_publisher_app_uid!, configuration.language)
      .then((geolocationResponse) => {
        configureAppWithUser(configuration, geolocationResponse);
      })
      .catch((error: AxiosError) => {
        handleError(error);
        console.error("Failed to resolve geolocation: ", error);
      });
  };

  const configureAppWithUser = (
    configuration: Configuration,
    { isSupported, language, isAnonymous }: Geolocation,
  ) => {

    if (language == null){
      language = DEFAULT_LANG;
    }

    loadTranslationResource(language);

    configuration.language = language;

    if (isAnonymous) {
      navigate("/vpn-blocked");
      return;
    }

    if (!isSupported) {
      navigate("/country-not-available");
      return;
    }

    getPanelistConfiguration(configuration?.hashed_publisher_app_uid ?? "", configuration?.publisher_app_uid ?? "", configuration?.device_id ?? "", configuration?.session_uid ?? "", configuration?.language)
      .then((userConfig) => {        
        getPublisherConfig(configuration.product_type!, configuration.hashed_publisher_app_uid!)
        .then((publisherConfig) => {
          finalizeAppConfiguration(publisherConfig, configuration, userConfig);
        })
        .catch((error) => {
          handleError(error);
          console.error("Fetch publisher config failed: ", error);
        });
      })
      .catch((error) => {
        console.error("Error fetching user configuration:", error);
        handleError(error);
      });
  };

  function getDeviceType() {
    const userAgent = window.navigator.userAgent;
    const screenWidth = window.innerWidth;
    if (userAgent.match(/Mobile/i)) {
      return DeviceTypes.Mobile;
    } else if (userAgent.match(/Tablet/i)) {
      return DeviceTypes.Tablet;
    } else if (screenWidth > 480) {
      return DeviceTypes.Desktop;
    } else {
      return DeviceTypes.Mobile;
    }
  }

  const finalizeAppConfiguration = (
    publisherConfig: PublisherConfig,
    configuration: Configuration,
    userConfig: PanelistConfig
  ) => {
    const { currency, flags, colors, currencySale, surveysEnabled, offersEnabled, profilerReward } =
      publisherConfig;

    configuration.currency_name = currency.name;
    configuration.currency_rate = currency.rate;
    configuration.surveys_enabled = surveysEnabled;
    configuration.offers_enabled = offersEnabled;
    configuration.profiler_reward = profilerReward;
    configuration.device_type = getDeviceType();

    if (window.self !== window.top){
      configuration.frame_origin = true;
    }

    const customColors = colors ? colors : { primaryColor: "#00a5ed", secondaryColor: "#f27536" };

    savePublisherFlags(flags);
    saveColors(customColors);
    saveCurrencySales(currencySale);
    
    setPrimaryColorCtx(customColors.primaryColor ?? "");
    setSecondaryColorCtx(customColors.secondaryColor ?? "");
    document.body.style.setProperty("--primary-color", customColors.primaryColor);
    document.body.style.setProperty("--secondary-color", customColors.secondaryColor);

    configuration.profiler_questions_required = !userConfig?.requiredQuestionsAnswered;
    configuration.panelist_stage = userConfig?.panelistStage || PanelistStages.PreBoostSurvey
    configuration.is_profiler_survey_started = userConfig?.isProfilerSurveyStarted || false;

    setCacheConfiguration(configuration);

    if (configuration.product_type === ProductTypes.NativeSurveys) {
      openNativeSurvey(configuration.survey_id!, configuration.search_id);
      return;
    }

    if (surveysEnabled){
      navigate("/surveys");
    }else{
      navigate("/offers");
    }
  };

  const openNativeSurvey = (surveyId: string, searchId?: string) => {
    getNativeSurveyLink(surveyId, searchId)
      .then((response) => {
        const {
          isProfiler,
          isEntryProfilerCompleted,
          externalId,
          virtualId,
          externalProviderId,
          link,
          reward,
        } = response;

        if (isProfiler) {
          navigate("/profiler", {
            state: { nativeSurveyId: externalId },
          });
          return;
        }

        setCurrentSurvey(
          externalId,
          ProductTypes.NativeSurveys,
          externalProviderId,
          virtualId,
          searchId,
          link,
        );

        navigate({
          pathname: "/pre-survey",
          search: createSearchParams({
            link: link,
            externalId: externalId,
            virtualId: virtualId || "",
            externalProviderId,
          }).toString()
        });
      })
      .catch((error) => {
        handleError(error);
        console.error("Fetch native survey link failed: ", error);
      });
  };

  return (
    <div className={c["configuration-page"]}>
      <Spinner size="normal" />
    </div>
  );
}

export default ConfigurationPage;
