import * as c from "./RewardModalDescriptionTask.module.css";

interface RewardModalDescriptionTaskProps {
  description: string;
}

const RewardModalDescriptionTask = ({ description }: RewardModalDescriptionTaskProps) => {
  return (
    <div className={c.task}>
      <div className={c["task-content-container"]}>
        <span>{description}</span>
      </div>
    </div>
  );
};

export default RewardModalDescriptionTask;
