import { useEffect, useMemo, useState } from "react";
import { useErrorHandler } from "contexts/errorContext";
import { getLink } from "api/offers";
import { Goal } from "interfaces/Goal";
import { getCachedConfiguration } from "utils/functions/configurationUtil";
import { transformCurrency } from "utils/functions/currencyModUtil";
import { TiVendorAndroid } from "react-icons/ti";
import { TiVendorApple } from "react-icons/ti";
import { SiMacos } from "react-icons/si";
import { ImWindows } from "react-icons/im";
import { FaAmazon } from "react-icons/fa";
import RewardModalDescriptionTask from "./RewardModalDescriptionTask";
import RewardModalGoalTask from "./RewardModalGoalTask";
import * as c from "./RewardModalContent.module.css";

interface RewardModalContentProps {
  modalContent: {
    attemptedAt: string;
    goals?: Goal[];
    description?: string[];
    platforms: string[];
    rewardIac: number;
    title: string;
    thumbnailUrl: string;
    offerId: number;
  };
}

type Platform = "Android" | "iOS" | "Windows" | "MacOS" | "Amazon";

const platformIcons: Record<Platform, JSX.Element> = {
  Android: <TiVendorAndroid size={"1.1em"} color="#A6D864" />,
  iOS: <TiVendorApple size={"1.1em"} />,
  Windows: <ImWindows />,
  MacOS: <SiMacos size={"1.4em"} />,
  Amazon: <FaAmazon size={"1em"} />,
};

const RewardModalContent = ({ modalContent }: RewardModalContentProps) => {
  const handleError = useErrorHandler();
  const { attemptedAt, goals, description, platforms, rewardIac, title, thumbnailUrl, offerId } =
    modalContent;
  const configuration = getCachedConfiguration();
  const currency = transformCurrency(configuration!.currency_name!, rewardIac);
  const [daysRemaining, setDaysRemaining] = useState<number | null>(null);
  const [hoursRemaining, setHoursRemaining] = useState<number | null>(null);
  const [url, setUrl] = useState("");

  const indexZeroTitle = useMemo(() => {
    return goals![0].title;
  }, [goals]);

  useEffect(() => {

    getLink(offerId, configuration!.hashed_publisher_app_uid!)
      .then((redirectUrl) => {
         setUrl(redirectUrl);
      })
      .catch((error) => {
        handleError(error);
      });

    const calculateTimeRemaining = () => {
      const now = new Date();
      const lastAttemptDate = new Date(attemptedAt);
      const diffInMs = now.getTime() - lastAttemptDate.getTime();

      const totalDurationMs = 7 * 24 * 60 * 60 * 1000;
      const remainingMs = totalDurationMs - diffInMs;

      if (remainingMs > 0) {
        const remainingDays = Math.floor(remainingMs / (1000 * 60 * 60 * 24));
        const remainingHours = Math.floor((remainingMs % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));

        if (remainingDays > 0) {
          setDaysRemaining(remainingDays);
          setHoursRemaining(null);
        } else {
          setDaysRemaining(null);
          setHoursRemaining(remainingHours);
        }
      } else {
        setDaysRemaining(0);
        setHoursRemaining(0);
      }
    };

    calculateTimeRemaining();
  }, [attemptedAt]);

  const getTotalCompletedReward = (goals: Goal[]) => {
    return goals.reduce((total, goal) => {
      if (goal.isCompleted) {
        return total + goal.rewardIac;
      }
      return total;
    }, 0);
  };

  return (
    <div className={c["modal-content"]}>
      <div className={c.header}>
        <img src={thumbnailUrl} alt="Game Icon" className={c.icon} />
        <div className={c["header-text"]}>
          <span className={c["title"]}>{title}</span>
          <div className={c["platform-icons"]}>
            {platforms &&
              platforms.length > 0 &&
              platforms.map((platform) => (
                <span key={platform}>{platformIcons[platform as Platform] || null}</span>
              ))}
          </div>
          <span className={c["sub-title"]}>Earn up to {currency} with this offer</span>
          <span className={c["earn-up"]}>
            {getTotalCompletedReward(goals!)} / {currency}
          </span>
        </div>
      </div>
      {description && description.length && (
        <div className={c.tasks}>
          {description!.map((description, index) => (
            <RewardModalDescriptionTask key={index} description={description} />
          ))}
        </div>
      )}
      {goals && goals.length > 0 && (
        <div className={c.tasks}>
          {goals.map((goal, index) => {
            return (
              <RewardModalGoalTask
                key={goal.id}
                goal={goal}
                currencyName={configuration!.currency_name!}
                index={index}
                daysRemaining={daysRemaining!}
                hoursRemaining={hoursRemaining!}
                indexZeroTitle={indexZeroTitle}
              />
            );
          })}
        </div>
      )}
      <a href={url} target="_blank" className={c["sign-up-button"]}>
        Get started and earn up to <span className={c.points}>&nbsp;{currency}</span>
      </a>
    </div>
  );
};

export default RewardModalContent;
