import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { getCurrentSurvey, getPublisherFlags, getCachedConfiguration } from "utils/functions/configurationUtil";
import { ProductTypes } from "utils/constants/enums";
import { notifyCloseWebView } from "utils/functions/systemNotificationUtil";
import SurveyNotFit from "../../assets/images/survey-not-fit.svg";

import * as c from "./BrokenSurveyThanksPage.module.css";

function BrokenSurveyThanksPage() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const translatedString = t("BROKEN_SURVEY_PAGE.APOLOGIZE.DESCRIPTION");
  const configuration = getCachedConfiguration();

  const isNative = () => {
    const currentSurvey = getCurrentSurvey();
    return currentSurvey
      ? currentSurvey?.isNativeSurvey
      : configuration?.product_type == ProductTypes.NativeSurveys;
  };

  const backToSurveyWall = () => {
    const { isNativeSurveysInAppRoutingEnabled } = getPublisherFlags() || {};
    const isNativeSurvey = isNative();

    if (isNativeSurveysInAppRoutingEnabled && isNativeSurvey) {
      notifyCloseWebView();
      return;
    }
    navigate("/surveys");
  };

  return (
    <div className={c["page-content"]}>
      <h1>{t("BROKEN_SURVEY_PAGE.APOLOGIZE.HEADER")}</h1>

      <img src={SurveyNotFit} />

      <h2 dangerouslySetInnerHTML={{ __html: translatedString }}></h2>

      <button onClick={backToSurveyWall}>
        <h3>{t("BROKEN_SURVEY_PAGE.APOLOGIZE.GO_BACK_BUTTON")}</h3>
      </button>
    </div>
  );
}

export default BrokenSurveyThanksPage;
