import { useCallback, useEffect, useState } from "react";
import { AxiosError } from "axios";
import { getOffers } from "api/offers";
import { useErrorHandler } from "contexts/errorContext";
import { Offer } from "interfaces/Offer";
import { getCachedConfiguration } from "utils/functions/configurationUtil";
import OfferCardItem from "../../components/offers/OfferCardItem";
import OfferFilter from "../../components/offers/OfferFilter";
import Modal from "../../components/ui/Modal";
import OfferModalContent from "../../components/offers/OfferModal/OfferModalContent";
import SurveyWallHeader from "pages/SurveyWall/SurveyWallHeader/SurveyWallHeader";
import SurveyWallFooter from "pages/SurveyWall/SurveyWallFooter/SurveyWallFooter";
import Spinner from "components/ui/Spinner";
import * as c from "./OffersPage.module.css";

function OffersPage() {
  const handleError = useErrorHandler();
  const [modalOpen, setModalOpen] = useState(false);
  const [offers, setOffers] = useState<any>([]);
  const [offset, setOffset] = useState(0);
  const [hasMore, setHasMore] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [modalContent, setModalContent] = useState<{
    goals?: Offer["goals"];
    description?: string[];
    platforms: string[];
    title: string;
    rewardIac: number;
    thumbnailUrl?: string;
    offerId?: number;
  }>({ platforms: [], title: "", rewardIac: 0 });
  const configuration = getCachedConfiguration();

  const openModal = (offer: Offer) => {
    document.body.style.overflow = 'hidden';
    setModalContent({
      goals: offer.goals,
      description: offer.description,
      platforms: offer.platforms,
      title: offer.title,
      rewardIac: offer.rewardIac,
      thumbnailUrl: offer.thumbnailUrl,
      offerId: offer.id,
    });
    setModalOpen(true);
  };
  const closeModal = () => { 
    document.body.style.overflow = '';
    setModalOpen(false);
  };

  const LIMIT = 30;

  const loadOffers = useCallback(async () => {
    if (!hasMore) return;

    setIsLoading(true);

    var os = detectMobileOS();

    try {
      const newOffers = await getOffers(
        configuration!.hashed_publisher_app_uid!,
        os,
        offset,
        LIMIT,
      );
      if (newOffers.length < LIMIT) setHasMore(false);
      setOffers((prevOffers: any) => [...prevOffers, ...newOffers]);
      setOffset((prevOffset) => prevOffset + LIMIT);
      setIsLoading(false);
    } catch (error) {
      handleError(error as AxiosError);
    }
  }, [offset, hasMore]);

  useEffect(() => {
    loadOffers();
  }, []);

  useEffect(() => {
    if (isLoading) {
      document.body.classList.add(c["no-scroll"]);
    } else {
      document.body.classList.remove(c["no-scroll"]);
    }
  }, [isLoading]);

  const handleScroll = () => {
    const scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
    const scrollHeight = document.documentElement.scrollHeight || document.body.scrollHeight;
    const clientHeight = document.documentElement.clientHeight || window.innerHeight;

    if (scrollHeight - scrollTop <= clientHeight + 100 && !isLoading && hasMore) {
      loadOffers();
    }
  };

  function detectMobileOS() {
    var userAgent = navigator.userAgent || navigator.vendor || window.opera;

    // iOS detection
    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      return "iOS";
    }

    // Android detection
    if (/android/i.test(userAgent)) {
      return "Android";
    }

    // If neither iOS nor Android
    return "";
  }

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [isLoading, hasMore, offset]);

  return (
    <>
      {isLoading && (
        <div className={c["spinner-overlay"]}>
          <Spinner size="normal" />
        </div>
      )}
      <SurveyWallHeader />
      <div className={c["offers-page"]}>
        <div className={`${c["offers-container"]} ${offers.length == 0 ? c["no-offers"] : ""}`}>
          {/* <div className={c["offers-filter-container"]}>
              <OfferFilter />
            </div> */}
          {offers && offers.length > 0 ? (
            offers.map((offer: Offer) => (
              <OfferCardItem
                key={offer.id}
                onModalOpen={() => openModal(offer)}
                offer={offer}
                currencyName={configuration!.currency_name!}
              />
            ))
          ) : (
            <p className={c["no-offers-message"]}>No Offers Available</p>
          )}
        </div>
      </div>
      <SurveyWallFooter style={{ marginTop: 0 }} />
      <Modal show={modalOpen} onClose={closeModal}>
        <OfferModalContent modalContent={modalContent} />
      </Modal>
    </>
  );
}

export default OffersPage;
