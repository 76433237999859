import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useErrorHandler } from "contexts/errorContext";
import { useTranslation } from "react-i18next";
import SliderComponent from "components/ui/Slider";
import { BrokenSurveyData } from "interfaces/BrokenSurveyData";
import { postBrokenSurveyData } from "api/feedback";

import * as c from "./BrokenSurveyCollectInfoPage.module.css";
import { getCachedConfiguration, getPublisherFlags } from "utils/functions/configurationUtil";
import { ProductTypes } from "utils/constants/enums";
import { notifyCloseWebView } from "utils/functions/systemNotificationUtil";

function BrokenSurveyCollectInfoPage() {
  const [description, setDescription] = useState<string>("");
  const [totalCompleted, setTotalCompleted] = useState<number>(0);
  const handleError = useErrorHandler();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const configuration = getCachedConfiguration();
  const submitRequest = () => {

    const requestData: BrokenSurveyData = {
      description: description,
      totalCompleted: Math.round(totalCompleted / 1000),
    };

    // postBrokenSurveyData(requestData)
    //   .then(() => {
    //     navigate("/broken-survey/thanks");
    //   })
    //   .catch((error) => {
    //     handleError(error);
    //   });

    const { isNativeSurveysInAppRoutingEnabled } = getPublisherFlags() || {};
    const isNativeSurvey = configuration?.product_type == ProductTypes.NativeSurveys;

    if (isNativeSurveysInAppRoutingEnabled && isNativeSurvey) {
      notifyCloseWebView();
      return;
    }
    
    navigate("/surveys");
  };
  const handleSliderChange = (value: number) => {
    setTotalCompleted(value);
  };

  return (
    <div className={c["profiler-container"]}>
      <div className={c["inbrain-top-header"]}></div>
      <div className={c["page-content"]}>
          <h1>{t("FEEDBACK_PAGE.QUESTION")}</h1>
          <br/>
          <div className={c["broken-survey-content"]}>
            <textarea
              placeholder={t("BROKEN_SURVEY_PAGE.COLLECT_INFO.WHAT_HAPPEND_INPUT_PLACEHOLDER")}
              rows={4}
              cols={50}
              onChange={(e) => setDescription(e.target.value)}
            ></textarea>

            <h3>{t("BROKEN_SURVEY_PAGE.COLLECT_INFO.COMPLETE_LEVEL")}</h3>
            <SliderComponent
              min={0}
              max={100000}
              step={1000}
              ariaLabel="units"
              onChange={handleSliderChange}
            />
            <button onClick={submitRequest}>
              <h3>{t("FEEDBACK_PAGE.RESULT.RETURN_TO_WALL")}</h3>
            </button>
          </div>
      </div>
    </div>
  );
}

export default BrokenSurveyCollectInfoPage;
