import { FiChevronRight } from "react-icons/fi";
import { RiEqualizerFill } from "react-icons/ri";
import { transformCurrency } from "utils/functions/currencyModUtil";
import { Offer } from "interfaces/Offer";
import { Goal } from "interfaces/Goal";
import * as c from "./RewardOfferCardItem.module.css";

interface RewardOfferCardItemProps {
  onModalOpen: (offer: Offer) => void;
  offer: Offer;
  currencyName: string;
}

function RewardOfferCardItem({ onModalOpen, offer, currencyName }: RewardOfferCardItemProps) {
  const currency = transformCurrency(currencyName, offer.rewardIac);
  const date = new Date(offer.attemptedAt);

  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");
  const hours = String(date.getHours()).padStart(2, "0");
  const minutes = String(date.getMinutes()).padStart(2, "0");

  const timeZone = "CST";

  const formattedDate = `${year}-${month}-${day} ${hours}:${minutes} ${timeZone}`;

  const getTotalCompletedReward = (goals: Goal[]) => {
    return goals.reduce((total, goal) => {
      if (goal.isCompleted) {
        return total + goal.rewardIac;
      }
      return total;
    }, 0);
  };

  return (
    <div className={c.card} onClick={() => onModalOpen(offer)}>
      <div className={c["card-content"]}>
        <div className={c["text-content-wrapper"]}>
          <img src={offer.thumbnailUrl} alt="Logo" className={c.logo} />
          <div className={c["text-content"]}>
            <p className={c["card-title"]}>{offer.title}</p>
            <p className={c["card-currency"]}>Earn up to {currency}</p>
            <p className={c["card-earned"]}>
              {getTotalCompletedReward(offer.goals!)} / {currency} earned so far{" "}
              <span className={c["card-earned-divider"]}></span>
              <span className={c["card-earned-open"]}>View Progress</span>{" "}
            </p>
            {/* <p className={c["card-date"]}>{formattedDate}</p> */}
          </div>
        </div>
        <div className={c["completed-goal-wrapper"]}>
          <div className={c["completed-goal"]}>
            <RiEqualizerFill />
            <span>View Details</span>
            <FiChevronRight />
          </div>
        </div>
      </div>
    </div>
  );
}

export default RewardOfferCardItem;
