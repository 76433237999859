import footerLogo from "assets/images/footer-logo.svg";
import facebookIcon from "assets/images/facebook-icon.png";
import linkedinIcon from "assets/images/linkedin-icon.png";
import twitterIcon from "assets/images/twitter-icon.png";
import youtubeIcon from "assets/images/youtube-icon.png";
import * as c from "./SurveyWallFooter.module.css";

interface SurweyWallFooterProps {
  style: Object;
}

function SurveyWallFooter({ style }: SurweyWallFooterProps) {
  return (
    <div className={c["footer-container"]} style={style}>
      <div className={c["terms"]}>
        <img src={footerLogo} alt="footer-logo" />
        <a href="https://www.inbrain.ai/terms-and-conditions/terms-of-service" target="_blank">Terms of Services</a>
        <a href="https://www.inbrain.ai/terms-and-conditions/privacy-policy" target="_blank">Privacy Policy</a>
      </div>
      {/* <div className={c["social-icons"]}>
        <img src={facebookIcon} alt="facebook-icon" />
        <img src={linkedinIcon} alt="linkedin-icon" />
        <img src={twitterIcon} alt="twitter-icon" />
        <img src={youtubeIcon} alt="instagram-icon" />
      </div> */}
    </div>
  );
}

export default SurveyWallFooter;
