import * as surveysApi from "api/surveys";
import preSurveyIcon from "assets/images/pre-survey-icon.png";
import Spinner from "components/ui/Spinner";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { createSearchParams, useNavigate, useSearchParams } from "react-router-dom";
import {
  getCachedConfiguration,
  getCurrentSurvey,
  getPublisherFlags,
  getColors,
  getToken
} from "utils/functions/configurationUtil";
import * as c from "./PreSurveyPage.module.css";
import { useErrorHandler } from "contexts/errorContext";

function PreSurveyPage() {
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const [isSurveyOpening, setIsSurveyOpening] = useState(false);
  const [animateButton, setAnimateButton] = useState(false);

  const productType = getCurrentSurvey()!.productType;

  const surveyLink = searchParams.get("link")!;
  const externalId = searchParams.get("externalId")!;
  const virtualId = searchParams.get("virtualId")!;
  const configuration = getCachedConfiguration();
  const handleError = useErrorHandler();

  useEffect(() => {
  }, []);

  function addMsgEventListener(surveyLink:string, newTab:Window){
      const configuration = getCachedConfiguration();
      const currentSurvey = getCurrentSurvey();
      const publisherFlags = getPublisherFlags();
      const publisherColors = getColors();
      const jwtToken = getToken();

      function handleMessage(event:any) {
        if (event.source === newTab && event.data === 'frame-ready') {
          newTab.postMessage(
            {
              configuration: configuration,
              surveyLink: surveyLink,
              currentSurvey: currentSurvey,
              publisherFlags: publisherFlags,
              publisherColors: publisherColors,
              jwtToken: jwtToken
            },
            window.location.origin,
          );
        }else if (event.source === newTab && event.data === 'return-to-surveys')
        {
          navigate("/surveys");
          window.removeEventListener('message', handleMessage);
        }
      }

      window.addEventListener('message', handleMessage);

      newTab.location.href = window.location.origin + '/iframe-configuration';
  };

  function openSurvey() {
    setAnimateButton(true);
    setTimeout(() => {
      setIsSurveyOpening(true);
    }, 300);

    const { searchId } = getCurrentSurvey() || {};

    if (externalId == 'simulation_survey')
    {
      if (configuration?.frame_origin)
      {
        const newTab = window.open('', '_blank');
        if (newTab){
          addMsgEventListener(surveyLink, newTab);
          return;
        }
      }else{
        window.location.replace(surveyLink);
        return;
      }
    }
    
    surveysApi
      .setSurveyAsAttempted(externalId, virtualId, productType, configuration?.currency_rate, searchId)
      .then(({ surveyEntryLink, clientRedirectLink }) => {
        if (surveyEntryLink) {
          if (configuration?.frame_origin) {
            const newTab = window.open('', '_blank');
            if (newTab) {
              addMsgEventListener(surveyEntryLink, newTab);
              return;
            }
          }
          else {
            window.location.replace(surveyEntryLink);
            return;
          }
        }

        if (clientRedirectLink) {
          window.location.replace(clientRedirectLink);
          return;
        }

        navigate({
          pathname: "/error",
          search: createSearchParams({
            status: "Could Not Load Survey",
          }).toString(),
        });
      }).catch((error) => {
        handleError(error);
      }).finally(() => {
        
      });
  }

  return (
    <>
        <div className={c["pre-survey-page"]}>
          <div className={c["pre-survey-container"]}>
            <div className={c["pre-survey-content"]}>
              <div className={`${c["pre-survey-header"]} ${c["animated"]}`}>
                <div className={c["pre-survey-header-copy"]}>
                  {t("INBRAIN_THEME.PRE_SURVEY.DESCRIPTION")}
                </div>
                <img src={preSurveyIcon} />
              </div>
            </div>
            <div className={c["pre-survey-cta-container"]}>
              <button
                className={`${c["pre-survey-cta"]} ${isSurveyOpening ? c["disabled"] : ""} ${
                  animateButton ? c["animated"] : ""
                }`}
                disabled={isSurveyOpening}
                onClick={openSurvey}
              >
                {!isSurveyOpening ? (
                  t("INBRAIN_THEME.PRE_SURVEY.BUTTON_TEXT")
                ) : (
                  <Spinner size="small" />
                )}
              </button>
            </div>
          </div>
        </div>
    </>
  );
}

export default PreSurveyPage;
