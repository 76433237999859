import { useErrorHandler } from "contexts/errorContext";
import { postAttempt, getLink } from "api/offers";
import { Goal } from "interfaces/Goal";
import { getCachedConfiguration } from "utils/functions/configurationUtil";
import { transformCurrency } from "utils/functions/currencyModUtil";
import { TiVendorAndroid } from "react-icons/ti";
import { TiVendorApple } from "react-icons/ti";
import { SiMacos } from "react-icons/si";
import { ImWindows } from "react-icons/im";
import { FaAmazon } from "react-icons/fa";
import OfferModalGoalTask from "./OfferModalGoalTask";
import OfferModalDescriptionTask from "./OfferModalDescriptionTask";
import * as c from "./OfferModalContent.module.css";
import { useEffect, useState } from "react";

interface OfferModalContentProps {
  modalContent: {
    goals?: Goal[];
    description?: string[];
    platforms: string[];
    rewardIac: number;
    title: string;
    thumbnailUrl: string;
    offerId: number;
  };
}

type Platform = "Android" | "iOS" | "Windows" | "MacOS" | "Amazon";

const platformIcons: Record<Platform, JSX.Element> = {
  Android: <TiVendorAndroid size={"1.1em"} color="#A6D864" />,
  iOS: <TiVendorApple size={"1.1em"} />,
  Windows: <ImWindows />,
  MacOS: <SiMacos size={"1.4em"} />,
  Amazon: <FaAmazon size={"1em"} />,
};

const OfferModalContent = ({ modalContent }: OfferModalContentProps) => {
  const handleError = useErrorHandler();
  const { goals, description, platforms, rewardIac, title, thumbnailUrl, offerId } = modalContent;
  const configuration = getCachedConfiguration();
  const currency = transformCurrency(configuration!.currency_name!, rewardIac);
  const [url, setUrl] = useState("");

  useEffect(() => {
    getLink(offerId, configuration!.hashed_publisher_app_uid!)
      .then((redirectUrl) => {
         setUrl(redirectUrl);
      })
      .catch((error) => {
        handleError(error);
      });
  }, []);

  const handleSendAttempt = () => {
    postAttempt(offerId, configuration!.hashed_publisher_app_uid!)
      .then((redirectUrl) => {
         setUrl(redirectUrl);
      })
      .catch((error) => {
        handleError(error);
      });
  };

  return (
    <div className={c["modal-content"]}>
      <div className={c.header}>
        <img src={thumbnailUrl} alt="Game Icon" className={c.icon} />
        <div className={c["header-text"]}>
          <span className={c["title"]}>{title}</span>
          <div className={c["platform-icons"]}>
            {platforms &&
              platforms.length > 0 &&
              platforms.map((platform) => (
                <span key={platform}>{platformIcons[platform as Platform] || null}</span>
              ))}
          </div>
          <span className={c["sub-title"]}>Earn up to {currency} with this offer</span>
        </div>
      </div>
      {description && description.length && (
        <div className={c.tasks}>
          {description!.map((description, index) => (
            <OfferModalDescriptionTask key={index} description={description} />
          ))}
        </div>
      )}
      {goals && goals.length > 0 && (
        <div className={c.tasks}>
          {goals.map((goal, index) => (
            <OfferModalGoalTask
              key={goal.id}
              goal={goal}
              currencyName={configuration!.currency_name!}
              index={index}
            />
          ))}
        </div>
      )}

      <a href={url} onClick={handleSendAttempt} target="_blank" className={c["sign-up-button"]}>
        Get started and earn up to <span className={c.points}>&nbsp;{currency}</span>
      </a>
    </div>
  );
};

export default OfferModalContent;
