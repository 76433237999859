import { Route, Routes, Navigate } from 'react-router-dom';
import { ErrorHandlerProvider } from 'contexts/errorContext';
import { HeaderTabProvider } from 'contexts/tabContext';
import { ColorProvider } from './contexts/colorContext';
import ConfigurationPage from 'pages/Configuration/ConfigurationPage';
import ErrorPage from 'pages/Error/ErrorPage';
import PreSurveyPage from 'pages/PreSurvey/PreSurveyPage';
import ProfilerPage from 'pages/Profiler/ProfilerPage';
import SimulationSurveyPage from 'pages/SimulationSurvey/SimulationSurveyPage';
import SurveyWallPage from 'pages/SurveyWall/SurveyWallPage';
import OffersPage from 'pages/Offers/OffersPage';
import RewardsPage from 'pages/Rewards/RewardsPage';
import SimulationCallbackPage from 'pages/Callbacks/SimulationCallbackPage';
import SupplierSurveyPage from 'pages/SupplierSurvey/SupplierSurveyPage';
import DynataRexCallbackPage from 'pages/Callbacks/DynataRexCallbackPage';
import DynataEngageCallbackPage from 'pages/Callbacks/DynataEngageCallbackPage';
import LucidCallbackPage from 'pages/Callbacks/LucidCallbackPage';
import BrokenSurveyPage from 'pages/BrokenSurvey/BrokenSurveyPage';
import BrokenSurveyCollectInfoPage from 'pages/BrokenSurvey/BrokenSurveyCollectInfoPage';
import BrokenSurveyThanksPage from 'pages/BrokenSurvey/BrokenSurveyThanksPage';
import VpnBlockedPage from 'pages/VpnBlocked/VpnBlockedPage';
import CountryBlockedPage from 'pages/CountryBlocked/CountryBlockedPage';
import GetMoreSurveysPage from 'pages/GetMoreSurveys/GetMoreSurveysPage';
import IFrameConfigurationPage from 'pages/PreSurvey/IFrameConfiguration';
import InitialConfigurationPage from 'pages/Configuration/InitialConfigurationPage';

function App(): JSX.Element {
  return (
    <ErrorHandlerProvider>
      <HeaderTabProvider>
        <ColorProvider>
          <Routes>
            <Route path="/" element={<Navigate to="/config" replace />} />
            <Route path="/config" element={<InitialConfigurationPage />} />
            <Route path="/configuration" element={<ConfigurationPage />} />
            <Route path="/profiler" element={<ProfilerPage />} />
            <Route path="/vpn-blocked" element={<VpnBlockedPage />} />
            <Route path="/country-not-available" element={<CountryBlockedPage />} />
            <Route path="/feedback" element={<BrokenSurveyPage />} />
            <Route path="/feedback/collect-info" element={<BrokenSurveyCollectInfoPage />} />
            <Route path="/feedback/thanks" element={<BrokenSurveyThanksPage />} />
            <Route path="/surveys" element={<SurveyWallPage />} />
            <Route path="/offers" element={<OffersPage />} />
            <Route path="/rewards" element={<RewardsPage />} />
            <Route path="/pre-survey" element={<PreSurveyPage />} />
            <Route path="/simulation-survey" element={<SimulationSurveyPage />} />
            <Route path="/callback/simulation-survey/:type" element={<SimulationCallbackPage />} />
            <Route path="/callback/dynata-rex" element={<DynataRexCallbackPage />} />
            <Route path="/callback/dynata-engage" element={<DynataEngageCallbackPage />} />
            <Route path="/callback/lucid-api/:type" element={<LucidCallbackPage />} />
            <Route path="/supplier-surveys/:surveyId" element={<SupplierSurveyPage />} />
            <Route path="/get-more-surveys" element={<GetMoreSurveysPage />} />
            <Route path="/iframe-configuration" element={<IFrameConfigurationPage />} />
            <Route path="/error" element={<ErrorPage />} />
          </Routes>
        </ColorProvider>
      </HeaderTabProvider>
    </ErrorHandlerProvider>
  );
}
export default App;
